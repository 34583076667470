@import 'webresources-sources/scss/imports';

.category-teasers {
  .col {
    .category-teaser {
      .font-display {
        font-weight: 600;
        word-break: break-word;

        @include media-breakpoint-up(lg) {
          font-size: 32px;
          min-height: calc(32px * 2);
        }

        @include media-breakpoint-up(xl) {
          font-size: 36px;
          min-height: calc(36px * 2);
        }
      }

      svg {
        display: none;
      }

      &::after {
        transition: all 500ms linear;
        content: ' ';
        display: flex;
        align-items: center;
        justify-content: center;
        width: 54px;
        height: 54px;
        background-color: var(--text-color);
        position: absolute;
        left: 16px;
        bottom: 16px;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center center;
      }

      &:hover {
        img {
          opacity: 0.4;
        }

        &::after {
          width: 60px;
          height: 60px;
        }
      }
    }
  }

  .col:nth-child(1) .category-teaser::after {
    mask-image: $icon-tile-1;
  }

  .col:nth-child(2) .category-teaser::after {
    mask-image: $icon-tile-2;
  }

  .col:nth-child(3) .category-teaser::after {
    mask-image: $icon-tile-3;
  }

  .col:nth-child(4) .category-teaser::after {
    mask-image: $icon-tile-4;
  }
}
